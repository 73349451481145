export default {
  indent: {
    __default: {
      display: 'inline-block',
      textIndent: 'var(--text-indent, 1em)',

      'b, strong': {
        color: 'primary',
        fontWeight: '300!important',
      },
    },
  },
  icon: { size: { '--stroke-absolute': (t) => t.borderWidths[1] + 'px' } },
  layout: {
    section: {
      regular: {
        my: 3,
      },
      heading: {
        mt: 3,
        mb: 2,
      },
    },
  },
}
