module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","titleTemplate":"%s | Styllar Token™","title":"Styllar Token™","description":"Styllar Token™ is building a sustainable digital universe. Innovation through creativity & technology","copyrights":"©2022 Styllar Token™ All rights reserved","openGraph":{"type":"website","locale":"en_CA","images":[{"url":"https://styllar-token.netlify.app/styllar-token-social.png"}]},"twitter":{"handle":"@STYLLARNFT","site":"@STYLLARNFT","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","custom":[{"name":["GT America Mono"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
