import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { inset } from '@monobits/core'
import { omit } from 'lodash'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

import './gsap'

export default merge(omit(preset, ['colors']), {
  config,
  colors,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...rootFontStyles,
      ...rootScaleStyles,

      '--gradient': 'linear-gradient(38.83deg, #F25A1D 13.8%, #F9EAE4 83.82%)',

      '--icon-root-width': '0.6rem',
      '--icon-height': '1.2em',
      '--icon-width': '1.25em',

      '--ui-duration': '0.35s',
      '--ui-ease': 'ease-in',

      body: { bg: 'background' },
    },
  },

  transitions: {
    defaults: {
      duration: 0,
      nativeDuration: 0,
    },
  },
  motion: {
    collapsible: {
      transition: {
        duration: 0.4,
        ease: 'power1',
      },
      bezier: 'ease',
    },
  },

  ...fonts,
  ...scales,
  ...overrides,
  ...variants,
})
