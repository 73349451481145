export default {
  __default: {
    px: '2rem',
    pt: 'var(--pt)',
    pb: 'var(--pb)',

    '--pt': 'calc(2rem / 2)',
    '--pb': 'calc(2rem / 1.3)',

    minHeight: 'calc(2rem * 2.7)',
    alignItems: 'center',

    borderStyle: 'solid',
    borderColor: 'text',
    borderWidth: 1,

    borderTop: 'none',
    borderBottom: 'none',

    position: 'relative',

    '::before,::after': {
      content: '""',
      display: 'flex',

      width: '2.5rem',
      height: 'var(--stroke-x)',

      bg: 'text',

      position: 'absolute',
      bottom: 0,
    },
    '::before': { left: 0 },
    '::after': { right: 0 },

    '&[data-clear="left"]': { borderLeft: 'none' },
    '&[data-clear="right"]': { borderRight: 'none' },
  },
}
