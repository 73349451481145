import { useRef } from 'react'
import { useConditionalEffect } from '@react-hookz/web'
import { useStateRef } from '@monobits/gsap'
import { gsap } from 'gsap'
import { Lottie } from '../components/Lottie'

import horizontal from '../../assets/lottie/loader.json'
import vertical from '../../assets/lottie/loader_vertical.json'

const Intro = ({ state, breakpoint }) => {
  const [, setHidden] = state ?? []
  let player = useRef(null)

  const [node, ref] = useStateRef()

  const viewBreak = breakpoint > 1

  useConditionalEffect(
    () => {
      const timeout = setTimeout(() => player.current.play(), 400)
      return () => clearTimeout(timeout)
    },
    [player, breakpoint],
    [breakpoint != null]
  )

  return (
    <aside ref={ref} sx={{ variant: 'intro' }} data-view={viewBreak ? 'horizontal' : 'vertical'}>
      <Lottie
        ref={player}
        json={viewBreak ? horizontal : vertical}
        onComplete={() => {
          gsap
            .timeline({ onComplete: () => setHidden(true) })
            .set('html', { '--blur': '4px' })
            .to(node?.childNodes?.[0], { autoAlpha: 0, duration: 0.5, ease: 'power1.out' }, 'start')
            .add('start')
            .to(node, { autoAlpha: 0, duration: 0.7, ease: 'power1.out' }, 'start')
            .to('html', { '--blur': '0px', duration: 0.8, ease: 'power1.out' }, 'start+=0.3')
        }}
        options={{
          autoplay: false,
          loop: false,
        }}
      />
    </aside>
  )
}

export default Intro
