import { transitions, easeInOut, easeIn, easeOut } from 'polished'

export default {
  alpha: 1,
  __default: {
    '--stroke': '100%',
    py: 'clamp(4px, 0.08em, 100px)',

    '--spacing': '100%',

    textDecoration: 'none',
    backgroundImage: 'linear-gradient(var(--from), var(--from)), linear-gradient(var(--to), var(--to))',
    backgroundPosition: '100% var(--spacing), 0 var(--spacing)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% var(--stroke), 0 var(--stroke)',

    '--speed': '0.8s',

    transition: `
      background-size var(--speed) ${easeInOut('cubic')},
      opacity var(--speed) ${easeInOut('cubic')},
      color var(--speed) ${easeInOut('cubic')}
    `,

    '@media (hover: hover)': {
      '&:hover': {
        backgroundSize: '0 var(--stroke), 100% var(--stroke)',
        color: 'background',

        '--speed': '0.08s',
        // '--speed': '0s',

        transition: `
          background-size var(--speed) ${easeOut('cubic')},
          opacity var(--speed) ${easeOut('cubic')},
          color var(--speed) ${easeOut('cubic')}
        `,
      },
    },
  },
}
