export default {
  useLocalStorage: true,
  useColorSchemeMediaQuery: false,

  initialColorModeName: 'light',

  useStickyHeader: false,
  useShyHeader: false,

  colorTransitions: {
    duration: 0.5,
    ease: 'power1.inOut',
  },

  images: {
    steps: 300,
    params: { quality: 100 },
    object: { fit: 'cover', position: 'center center' },
    transition: 'var(--ui-duration) var(--ui-ease)',
    placeholder: false,
    // placeholder_modifiers: '/1x1/filters:quality(1)',
    fade: true,
    observer: {
      rootMargin: '25%',
      threshold: 0.0,
    },
  },
}
