import { useEffect, createContext } from 'react'
import { getWindow } from 'ssr-window'
import { Box } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useSessionStorageValue } from '@react-hookz/web'
import { Sizes } from '@monobits/components'
import Intro from './Intro'

const window = getWindow()
export const AppContext = createContext()

const Layout = ({ children, location }) => {
  const [rendered, setRendered, removeRendered] = useSessionStorageValue('app-rendered', false, {
    handleStorageEvent: true,
    isolated: false,
  })

  const breakpoint = useBreakpointIndex()

  window.onunload = () => removeRendered()

  useEffect(() => document.documentElement.setAttribute('data-break', breakpoint), [breakpoint])

  return (
    <AppContext.Provider value={{ breakpoint }}>
      <Sizes callbacks={location} />

      {!rendered && <Intro state={[rendered, setRendered]} breakpoint={breakpoint} />}

      <Box as="main" id="main" sx={{ variant: 'main' }}>
        {children}
      </Box>
    </AppContext.Provider>
  )
}

export default Layout
