export default {
  __default: {
    bg: 'background',
    overflow: 'hidden',

    position: 'relative',

    '--gap': 'var(--padding-x)',

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',

    '[data-gsap]': {
      gridColumn: '1/2',
      gridRow: '1/2',
    },
  },
  video: {
    position: 'fixed',
    inset: 0,
    zIndex: 1,

    display: 'grid',
    placeContent: 'center',

    filter: 'blur(var(--blur, 0px))',
    willChange: 'auto',
  },
  container: {
    position: 'relative',
    zIndex: 2,

    width: '100%',
    height: 'calc(100 * var(--vh-x, 1vh))',

    p: 1,

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',

    gap: 'var(--gap)',

    '[data-area]': {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: 0,

      placeContent: 'center',
    },
  },
  burger: {
    size: '1.2rem',

    border: 'var(--stroke-x) solid currentColor',
    borderRadius: '50%',

    transition: 'background-color var(--ui-duration) var(--ui-ease)',

    '&[data-active=false]': {
      bg: 'text',
    },
  },
}
