import { forwardRef, useMemo } from 'react'
import { useThemeUI } from 'theme-ui'

import LottiePlayer from 'lottie-react'
import { flatten } from 'lottie-colorify'

const Lottie = forwardRef(({ watch = [], color_key = 'text', json, options, ...props }, ref) => {
  const {
    colorMode,
    theme: { rawColors },
  } = useThemeUI() ?? {}

  const lottie = useMemo(
    () => {
      const hex = rawColors?.[color_key] ?? '#000000'
      const colorized = flatten(hex, json)

      return !!json ? (
        <LottiePlayer
          lottieRef={ref}
          animationData={colorized}
          sx={{ 'g[font-size]': { fill: hex } }}
          {...options}
          {...props}
        />
      ) : null
    },
    [color_key, options, json, colorMode, ...watch] // eslint-disable-line
  )

  return lottie
})

export default Lottie
