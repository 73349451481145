import { makeHighlight } from '@monobits/colors'

const opacity = 0.15
const sub_opacity = 0.4

const colors = {
  light: '#CEFF00',
  dark: '#000000',
}

export default {
  text: colors.light,
  background: colors.dark,
  primary: colors.light,
  highlight: makeHighlight(colors.light, opacity),
  subdued: makeHighlight(colors.light, sub_opacity),
  ...colors,
}
