export default {
  __default: {
    bg: 'background',
    p: 1,

    zIndex: 'popup',
    position: 'fixed',
    inset: 0,

    display: 'flex',
    alignItems: 'center',

    '&[data-view="horizontal"]': {
      justifyContent: 'center',

      '>div': { maxWidth: '42rem' },
    },
    '&[data-view="vertical"]': {
      justifyContent: 'flex-end',

      '>div': { height: '100%' },
      svg: { width: 'unset!important' },
    },
  },
}
