import { RecoilRoot } from 'recoil'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
  if (typeof ResizeObserver === 'undefined') {
    let { install } = await import('resize-observer')
    install()
  }
}

export const wrapRootElement = ({ element }) => {
  return <RecoilRoot>{element}</RecoilRoot>
}
