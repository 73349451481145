import { buildFonts, fontlist } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'size-text': ['11px', '16px'],
    'size-caption': ['11px', '16px'],
    'size-title': ['50px', '130px'],
  },
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 300,
    heading: 300,
  },
  lineHeights: {
    body: 1.26,
    heading: 1.1,
  },
}

export const rootFontStyles = {
  fontSize: 1,
  fontFamily: 'gtAmerica',
  lineHeight: 'body',
  fontWeight: 'body',

  '--rte': '1.35em',
  '.-rte + .-rte': { mt: 'var(--rte)' },

  ...fluid.properties,
}

const common = (type = 'body', uppercase = false) => ({
  textTransform: uppercase ? 'uppercase' : null,
  fontFamily: type,
  fontWeight: type,
  lineHeight: type,
  crops: 'gtAmerica',
})

const gtAmerica = {
  family: 'GT America Mono',
  fallbacks: 'monospace, helvetica, arial, sans-serif',
  crop: [9.65, 7],
}

export default buildFonts([fontlist(['gtAmerica'], { gtAmerica }), scales], {
  fonts: {
    heading: 'inherit',
    body: 'inherit',
    monospace: 'Menlo, monospace',
  },
  variants: {
    key: 'text',
    heading: { variant: 'text.title' },
    __default: { variant: 'text.text' },
    __cropped: {
      title: { fontSize: 3, ...common('heading', false) },
      caption: { fontSize: 2, ...common('heading', true) },
      text: { fontSize: 1, ...common('body') },
    },
  },
})
